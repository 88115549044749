import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AppComponent } from './app.component';

const appRoutes: Routes = [
  {
    path: 'myRootlogin',
    loadChildren : './login/login.module#LoginModule'
  },
  {
    path: 'myRootdashboard',
    loadChildren : './dashboard/dashboard.module#DashboardModule'
  },
  {
    path: 'myRootRegistration',
    loadChildren : './login/login.module#LoginModule'
  }

];

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    RouterModule.forRoot(
      appRoutes
    )
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
